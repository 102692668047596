exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-brand-detail-tsx": () => import("./../../../src/templates/brandDetail.tsx" /* webpackChunkName: "component---src-templates-brand-detail-tsx" */),
  "component---src-templates-company-tsx": () => import("./../../../src/templates/company.tsx" /* webpackChunkName: "component---src-templates-company-tsx" */),
  "component---src-templates-inquiry-tsx": () => import("./../../../src/templates/inquiry.tsx" /* webpackChunkName: "component---src-templates-inquiry-tsx" */),
  "component---src-templates-locations-tsx": () => import("./../../../src/templates/locations.tsx" /* webpackChunkName: "component---src-templates-locations-tsx" */),
  "component---src-templates-main-tsx": () => import("./../../../src/templates/main.tsx" /* webpackChunkName: "component---src-templates-main-tsx" */),
  "component---src-templates-news-detail-tsx": () => import("./../../../src/templates/newsDetail.tsx" /* webpackChunkName: "component---src-templates-news-detail-tsx" */),
  "component---src-templates-news-list-tsx": () => import("./../../../src/templates/newsList.tsx" /* webpackChunkName: "component---src-templates-news-list-tsx" */),
  "component---src-templates-product-detail-tsx": () => import("./../../../src/templates/productDetail.tsx" /* webpackChunkName: "component---src-templates-product-detail-tsx" */),
  "component---src-templates-product-type-tsx": () => import("./../../../src/templates/productType.tsx" /* webpackChunkName: "component---src-templates-product-type-tsx" */),
  "component---src-templates-promotion-tsx": () => import("./../../../src/templates/promotion.tsx" /* webpackChunkName: "component---src-templates-promotion-tsx" */)
}

